import styled from 'styled-components';
import { useTranslations } from 'ilenia';

import { H2 } from '@bscom/components/Headers';
import { MAX_PAGE_WIDTH } from '@bscom/constants/widths';
import { SPACING_MOBILE } from '@bscom/constants/heights';
import { DefaultSpacing } from '@bscom/components/Spacing';
import { OutlinedButton } from '@bscom/components/Buttons';
import { HeadMetaTags } from '@bscom/components/HeadMetaTags';
import { BackgroundFullHeight } from '@bscom/components/Background';
import NavigationLegal from 'components/NavigationLegal';

import { BaseLayout } from './BaseLayout';

const Heading = styled(H2)`
  margin-bottom: ${SPACING_MOBILE / 2}px;
`;

const ErrorText = styled.div`
  text-align: center;
  margin-bottom: ${SPACING_MOBILE}px;
`;

export const ErrorPageLayout = ({
  metaTitle = 'Page Not Found',
  children,
  withLayout = false
}) => {
  const content = (
    <>
      <BackgroundFullHeight
        bgColor="pink"
        bgImage="https://images.ctfassets.net/b7g9mrbfayuu/ScDrk4uregpGM6hsuWw7T/cb3bac06f62825115a8ee45fcbbb168e/404-background-image.png"
      >
        <DefaultSpacing withXPadding contentWidth={MAX_PAGE_WIDTH}>
          {children}
        </DefaultSpacing>
      </BackgroundFullHeight>
    </>
  );

  return (
    <>
      <HeadMetaTags metaTitle={metaTitle} />
      <NavigationLegal />
      {withLayout ? <BaseLayout>{content}</BaseLayout> : content}
    </>
  );
};

export const ErrorPage = ({ statusCode = 404, ...props }) => {
  const [translations] = useTranslations();

  const getLocalized = identifier =>
    translations[`error/${statusCode}/${identifier}`];

  return (
    <ErrorPageLayout {...props}>
      <ErrorText>
        <Heading>{getLocalized('header')}</Heading>
        <div
          dangerouslySetInnerHTML={{ __html: getLocalized('description') }}
        />
      </ErrorText>
      <ErrorText>
        <OutlinedButton
          color="blue-dark"
          text={getLocalized('button')}
          href="/contact"
        />
      </ErrorText>
    </ErrorPageLayout>
  );
};
